<template>
    <div>
        <header-menu ref="HeaderMenu"></header-menu>
        <div class="view-content">
            <div class="banner">
                <div class="desc__box text_left">
                    <h1 class="banner_title b_b_space font-size_60">
                        彩蝶•流向管理
                    </h1>
                    <p class="banner_desc b_b_space font-size_20">
                        彩蝶·流向管理——提供精准的流向数据清洗、查询、统计和销售业绩核算服务。支持终端“进销存”和商业流通等“连续节点数据监控”，提供多维度分析与多维度预警。帮助企业建立主数据系统，优化营销管理的实时性，为企业管理层提供数字化高效决策与绩效管理。
                    </p>
                </div>
            </div>
            <div class="l_r_space t__space">
                <h2 class="nav__title">核心功能</h2>
                <div class="tab_Jurisdiction">
                    <a-tabs
                        default-active-key="1"
                        :tabBarStyle="{
                            borderBottom: 'none',
                        }"
                    >
                        <a-tab-pane key="1" tab="流向采集">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                        :xs="{ span: 24 }"
                                        :sm="{ span: 24 }"
                                        :md="{ span: 9, offset: 3 }"
                                        :lg="{ span: 9, offset: 3 }"
                                        :xl="{ span: 9, offset: 3 }"
                                        :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        style="max-width: 100%"
                                        src="@/assets/img/png/flow-steward_1.png"
                                        alt="流向采集"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">流向采集</p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            支持多种采集方式，如：DDI直连、网站抓取、邮件抓取、FTP上传。
                                        </p>
                                        <p>
                                            支持日报、周报、月报数据、销售采购库存数据。
                                        </p>
                                        <p>
                                            定时抓取，可远程下达抓取任务，实时监控，断开插件及网站预警
                                        </p>
                                        <p>
                                            独有的爬虫技术，是数据完整性的重要保障
                                        </p>
                                    </div>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="别名管理">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                        :xs="{ span: 24 }"
                                        :sm="{ span: 24 }"
                                        :md="{ span: 9, offset: 3 }"
                                        :lg="{ span: 9, offset: 3 }"
                                        :xl="{ span: 9, offset: 3 }"
                                        :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        style="max-width: 100%"
                                        src="@/assets/img/png/flow-steward_2.png"
                                        alt="别名管理"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">别名管理</p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            产品别名、规格别名、客户别名。。。
                                        </p>
                                        <p>
                                            快速识别系统内重复档案，对流向数据进行合并。
                                        </p>
                                        <p>
                                            资源沉淀，使用越久别名库越丰富，数据越准确
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="3" tab="智能清洗">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                        :xs="{ span: 24 }"
                                        :sm="{ span: 24 }"
                                        :md="{ span: 9, offset: 3 }"
                                        :lg="{ span: 9, offset: 3 }"
                                        :xl="{ span: 9, offset: 3 }"
                                        :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        style="max-width: 100%"
                                        src="@/assets/img/png/flow-steward_3.png"
                                        alt="智能清洗"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">智能清洗</p>
                                    <div class="Jurisdiction_desc">
                                        <p>自动识别清洗流向表头。</p>
                                        <p>自动过滤特殊符号。</p>
                                        <p>
                                            根据别名库智能清洗流向数据，并提示新增客户。
                                        </p>
                                        <p>
                                            AI智能学习，清洗次数越多，使用时间越久，清洗越精确。
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="4" tab="流向归属">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                        :xs="{ span: 24 }"
                                        :sm="{ span: 24 }"
                                        :md="{ span: 9, offset: 3 }"
                                        :lg="{ span: 9, offset: 3 }"
                                        :xl="{ span: 9, offset: 3 }"
                                        :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        style="max-width: 100%"
                                        src="@/assets/img/png/flow-steward_4.png"
                                        alt="流向归属"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">流向归属</p>

                                    <div class="Jurisdiction_desc">
                                        <p>清洗完数据入库归档。</p>
                                        <p>
                                            根据终端产品负责岗位自动计算流向归属人。
                                        </p>
                                        <p>招商模式流向自动归属代理商。</p>
                                        <p>流向的校验与调整。</p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="5" tab="多维度分析">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                        :xs="{ span: 24 }"
                                        :sm="{ span: 24 }"
                                        :md="{ span: 9, offset: 3 }"
                                        :lg="{ span: 9, offset: 3 }"
                                        :xl="{ span: 9, offset: 3 }"
                                        :xxl="{ span: 9, offset: 3 }"
                                >
                                    <img
                                        style="max-width: 100%"
                                        src="@/assets/img/png/flow-steward_5.png"
                                        alt="多维度分析"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">多维度分析</p>
                                    <div class="Jurisdiction_desc">
                                        <p>多维度销售排名</p>
                                        <p>预警/流失客户</p>
                                        <p>商业进销存统计、库存周转天数预警</p>
                                        <p>进货异常客户预警</p>
                                        <p>疑似窜货流向预警</p>
                                        <p>各地区单产分析</p>
                                        <p>多维度分析报表</p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                    </a-tabs>
                </div>
            </div>
            <div class="l_r_space t__space" style="background-color: #eee">
                <h2 class="nav__title">为什么选择正也科技</h2>
                <h3 class="nav__desc" style="padding: 0.1rem 0 0.4rem 0">
                    行业有深度 数据有态度
                </h3>
                <div>
                    <a-row :gutter="20" type="flex">
                        <a-col
                            style="padding: 0 0.1rem 20px 0.1rem"
                            :xs="24"
                            :sm="12"
                            :md="12"
                            :lg="8"
                            :xl="8"
                            :xxl="8"
                            v-for="(item, index) in softWareList"
                            :key="index"
                        >
                            <a-card>
                                <a-row style="align-items: center" type="flex">
                                    <a-col
                                        :xs="24"
                                        :sm="10"
                                        :md="10"
                                        :lg="10"
                                        :xl="10"
                                        :xxl="10"
                                        ><img
                                            :src="item.img_url"
                                            :alt="item.title"
                                            style="width: 100%; max-width: 80px"
                                    /></a-col>
                                    <a-col
                                        :xs="24"
                                        :sm="14"
                                        :md="14"
                                        :lg="14"
                                        :xl="14"
                                        :xxl="14"
                                        ><div style="min-height: 1.1rem">
                                            <h3
                                                class="soft_title text_left b_b_space font-size_26"
                                            >
                                                {{ item.title }}
                                            </h3>
                                            <p
                                                class="soft_desc text_left b_b_space font-size_16"
                                            >
                                                {{ item.desc }}
                                            </p>
                                        </div></a-col
                                    >
                                </a-row>
                            </a-card>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Footer from '@/components/Footer'
import HeaderMenu from '@/components/HeaderMenu'
import soft_1 from '@/assets/img/png/soft_1.png'
import soft_2 from '@/assets/img/png/soft_2.png'
import soft_3 from '@/assets/img/png/soft_3.png'
import soft_4 from '@/assets/img/png/soft_4.png'
import soft_5 from '@/assets/img/png/soft_5.png'
import soft_6 from '@/assets/img/png/soft_6.png'
export default {
    name: 'flowSteward',
    beforeCreate() {},
    components: { Footer, HeaderMenu },
    data() {
        return {
            softWareList: [
                {
                    title: '行业深度',
                    desc:
                        '正也科技核心成员为医药营销管理咨询专家和多年医药行业软件开发实施经验。',
                    img_url: soft_1,
                },
                {
                    title: '资源丰富',
                    desc:
                        '公司拥有海量行业主数据供客户选择，以及丰富的关键管理识别经验',
                    img_url: soft_2,
                },
                {
                    title: '产品规范',
                    desc:
                        'S2P支持全面数据颗粒化、追求灵活配置，应对企业未来管理需求',
                    img_url: soft_3,
                },
                {
                    title: '敏捷开发',
                    desc:
                        '基于主数据平台深度颗粒化，不断快速迭代，满足企业的个性化需求',
                    img_url: soft_4,
                },
                {
                    title: '致力创新',
                    desc:
                        'S2P专注于研究“营销方法论”、“营销新算法”，“让数据有态度”',
                    img_url: soft_6,
                },
                // {
                //   title: "致力于创新",
                //   desc: "公司专注于研究如何使用信息化的方法，以“数”助销",
                //   img_url: soft_6,
                // },
            ],
        }
    },
    created() {},
    methods: {
        handleSubmit() {},
    },
}
</script>
<style scoped lang="scss">
@import '@/assets/style/color.scss';
.soft_title {
    padding: 0.15rem 0 0.1rem 0;
}
.soft_desc {
    color: $color-fixGray;
}
.banner {
    width: 100%;
    position: relative;
    background: url('~@/assets/img/png/flow_banner.png');
    background-size: 100% 100%;
    height: 8rem;
    overflow: hidden;
    .desc__box {
        position: absolute;
        top: 1.5rem;
        left: 13.5%;
        .banner_desc {
            width: 5.6rem;
            padding-top: 0.3rem;
        }
    }
}
.row_view-box {
    align-items: center;
    padding: 0rem 0 0.8rem 0;
}
</style>
